body {
  background-color: #2d3748;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



@tailwind base;
@tailwind components;
@tailwind utilities;

/* Navbar name button */
.repo-btn {
  color: #55beff;

}
.btn {
  --color: #55beff;
  --color2: rgb(10, 25, 30);
  padding: 0.8em 1.75em;
  background-color: transparent;
  border-radius: 6px;
  border: .3px solid var(--color);
  transition: .5s;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  font-weight: 300;
  font-size: 17px;
  font-family: 'Roboto', 'Segoe UI', sans-serif;
  text-transform: uppercase;
  color: var(--color);
 }
 
 .btn::after, .btn::before {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  transform: skew(90deg) translate(-50%, -50%);
  position: absolute;
  inset: 50%;
  left: 25%;
  z-index: -1;
  transition: .5s ease-out;
  background-color: var(--color);
 }
 
 .btn::before {
  top: -50%;
  left: -47%;
  transform: skew(90deg) rotate(180deg) translate(-50%, -50%);
 }
 
 .btn:hover::before {
  transform: skew(45deg) rotate(180deg) translate(-50%, -50%);
 }
 
 .btn:hover::after {
  transform: skew(45deg) translate(-50%, -50%);
 }
 
 .btn:hover {
  color: var(--color2);
 }
 
 .btn:active {
  filter: brightness(.7);
  transform: scale(.98);
 }
 /* navbar buttons */
 /* === removing default button style ===*/
.button {
  margin: 0;
  height: auto;
  background: transparent;
  padding: 0;
  border: none;
}

/* button styling */
.button, .resume-btn{
  --border-right: 6px;
  --text-stroke-color: rgba(255,255,255,0.6);
  --animation-color: #55beff;
  --fs-size: 1em;
  letter-spacing: 3px;
  text-decoration: none;
  font-size: var(--fs-size);
  font-family: "Arial";
  position: relative;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke: 1px var(--text-stroke-color);
}
/* this is the text, when you hover on button */
.hover-text {
  position: absolute;
  box-sizing: border-box;
  content: attr(data-text);
  color: var(--animation-color);
  width: 0%;
  inset: 0;
  border-right: var(--border-right) solid var(--animation-color);
  overflow: hidden;
  transition: 0.5s;
  -webkit-text-stroke: 1px var(--animation-color);
}
/* hover */
.button:hover .hover-text {
  width: 100%;
  filter: drop-shadow(0 0 23px var(--animation-color))
}
.resume-btn:hover .hover-text {
  width: 100%;
  filter: drop-shadow(0 0 23px var(--animation-color))
}

/* rotating cube */
:before,
:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.wrapper {
  height: 500px;
  width: 500px;
  position: absolute;
  margin: auto;
  left: 65%;
  right: 0;
  top: 0;
  bottom: 20%;
}

@media (max-width: 480px) {
  .wrapper {
    position: absolute;
    height: 300px;
    width: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 800px;
  perspective-origin: 50%;
}

.image-cube {
  width: 230px;
  height: 230px;
  transform-style: preserve-3d;
  position: relative;
  animation: spin 18s linear infinite;
}

@media (max-width: 768px) {
  .image-cube {
    width: 200px;
    height: 200px;
  }
}

@media (max-width: 480px) {
  .image-cube {
    width: 150px;
    height: 150px;
  }
}

@media (max-width: 320px) {
  .image-cube {
    width: 120px;
    height: 120px;
  }
}

@keyframes spin {
  100% {
    transform: rotateY(360deg);
  }
}

.image-cube div {
  height: 300px;
  width: 300px;
  position: absolute;
  backface-visibility: hidden;
}

img {
  width: 100%;
}

.front {
  transform: translateZ(150px);
}

.imgs {
  height: 100%;
  width: fit-content;
}

.right {
  transform: rotateY(-270deg) translateX(150px);
  transform-origin: 100% 0;
}

.back {
  transform: translateZ(-150px) rotateY(180deg);
}

.left {
  transform: rotateY(270deg) translateX(-150px);
  transform-origin: 0 50%;
}
/* contact button */
.social-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  box-shadow: 0px 0px 15px #00000027;
  padding: 15px 10px;
  border-radius: 5em;
  position: absolute;
  top: 80%;
}

.social-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 10px;
  background-color: #fff;
  box-shadow: 0px 0px 4px #00000027;
  transition: 0.3s;
}

.social-button:hover {
  background-color: #f2f2f2;
  box-shadow: 0px 0px 6px 3px #00000027;
}

.social-buttons svg {
  transition: 0.3s;
  height: 20px;
}

.facebook {
  background-color: #3b5998;
}

.facebook svg {
  fill: #f2f2f2;
}

.facebook:hover svg {
  fill: #3b5998;
}

.github {
  background-color: #333;
}

.github svg {
  width: 25px;
  height: 25px;
  fill: #f2f2f2;
}

.github:hover svg {
  fill: #333;
}

.linkedin {
  background-color: #0077b5;
}

.linkedin svg {
  fill: #f2f2f2;
}

.linkedin:hover svg {
  fill: #0077b5;
}

.instagram {
  background-color: #c13584;
}

.instagram svg {
  fill: #f2f2f2;
}

.instagram:hover svg {
  fill: #c13584;
}
/* project cards */
/* .header {
  position: absolute;
  bottom: 33rem;
} */
.card {
  width: 400px;
  height: 550px;
  background-image: linear-gradient(163deg, #55beff 0%, #3700ff 100%);
  border-radius: 20px;
  transition: all .3s;
 }
 
 .card2 {
  width: 400px;
  height: 550px;
  background-color: #2d3748;
  border-radius:20px;
  transition: all .2s;
 }
 
 .card2:hover {
  transform: scale(0.98);
  border-radius: 20px;
 }
 
 .card:hover {
  box-shadow: 0px 0px 30px 1px rgba(0, 195, 255, 0.972);
 }
 /* Project repo buttons */
 .project-repo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  gap: 15px;
  background-color: #55beff;
  outline: 3px #55beff solid;
  outline-offset: -3px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: 400ms;
}
 .project-repo-no-be {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  gap: 15px;
  background-color: #55beff;
  outline: 3px #55beff solid;
  outline-offset: -3px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: 400ms;
}

.project-repo, .project-repo-no-be .text {
  color: white;
  font-weight: 700;
  font-size: 1em;
  transition: 400ms;
}

.project-repo, .project-repo-no-be svg path {
  transition: 400ms;
}

.project-repo:hover {
  background-color: transparent;
}
.project-repo-no-be:hover {
  background-color: transparent;
}

.projet-repo:hover .text {
  color: #181717;
}
.projet-repo-no-be:hover .text {
  color: #181717;
}

.project-repo:hover svg path {
  fill: #55beff;
}
.project-repo-no-be:hover svg path {
  fill: #55beff;
}
